import React from 'react';
import { Skeleton, SkeletonBlock, SkeletonContent, SkeletonLine } from '@one-thd/sui-atomic-components';

export function ConsumerLoadingLayout() {

  const loadingLayoutGrid = [
    'sui-grid sui-grid-cols-1 md:sui-grid-cols-2 lg:sui-grid-cols-3',
    'sui-gap-8 md:sui-gap-y-10 md:sui-gap-x-5'
  ].join(' ');
  const loadingLayoutGridTwo = [
    'sui-mt-4 sui-grid sui-grid-cols-1 md:sui-grid-cols-2 lg:sui-grid-cols-4',
    'sui-gap-8 md:sui-gap-y-10 md:sui-gap-x-5'
  ].join(' ');

  return (
    <div>
      <div className={loadingLayoutGrid}>
        <div className="sui-mr-2">
          <Skeleton disablePadding className="sui-max-w-full" SkeletonContentProps={{ grow: true }}>
            <SkeletonBlock aspect="square" height="unset" />
          </Skeleton>
        </div>

        <div className="sui-mr-2">
          <Skeleton disablePadding className="sui-max-w-full" SkeletonContentProps={{ grow: true }}>
            <SkeletonBlock aspect="square" height="unset" />
          </Skeleton>
        </div>

        <div className="sui-mr-2">
          <Skeleton SkeletonContentProps={{ grow: true }}>
            <SkeletonBlock aspect="wide" height="unset" />
            <SkeletonLine variant="multi" numberOfLines={4} />
          </Skeleton>
        </div>

        <div className="sui-mr-2 sui-col-span-3">
          <Skeleton disablePadding className="sui-max-w-full" SkeletonContentProps={{ grow: true }}>
            <SkeletonBlock aspect="wide" />
          </Skeleton>
        </div>
      </div>

      <div className={loadingLayoutGridTwo}>
        <div className="sui-mr-2">
          <Skeleton SkeletonContentProps={{ grow: true }}>
            <SkeletonBlock aspect="square" height="unset" />
            <SkeletonLine variant="multi" numberOfLines={3} />
          </Skeleton>
        </div>

        <div className="sui-mr-2">
          <Skeleton SkeletonContentProps={{ grow: true }}>
            <SkeletonBlock aspect="square" height="unset" />
            <SkeletonLine variant="multi" numberOfLines={3} />
          </Skeleton>
        </div>

        <div className="sui-mr-2">
          <Skeleton SkeletonContentProps={{ grow: true }}>
            <SkeletonBlock aspect="square" height="unset" />
            <SkeletonLine variant="multi" numberOfLines={3} />
          </Skeleton>
        </div>

        <div className="sui-mr-2">
          <Skeleton SkeletonContentProps={{ grow: true }}>
            <SkeletonBlock aspect="square" height="unset" />
            <SkeletonLine variant="multi" numberOfLines={3} />
          </Skeleton>
        </div>

      </div>
    </div>
  );
}
